export default {
  siteTitle: 'Get bonus',
  blogerName: 'PARBEK',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@parbek_win_'
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/people/Parbek-TV/100063480971813/'
    },
    {
      name: 'whatsapp',
      url: 'https://chat.whatsapp.com/FqzVL31kiC82lLYlsSrKwQ'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/ca81871bc',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c7f38392d',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c8c2c16d1',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c42604edc',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c6b841125',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cbeffdcc8',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cc4e7a98a',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c334cd1ab',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>PARBEK</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PARBEK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
